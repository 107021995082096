import React from "react";
import styled from "styled-components";
import Logo from "../logo";
import SearchBar from "../searchBar";
import { useNavigate } from "react-router-dom";

const HeaderContainer = styled.div`
  padding: 2em 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
`;

const Header = () => {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate("/");
  };

  return (
    <HeaderContainer>
      <Logo onClick={navigateHome} />
      <SearchBar />
    </HeaderContainer>
  );
};

export default Header;