import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackButtonContainer = styled(Link)`
  display: flex;
  flex-direction: row;
`;

type ComponentProps = {
  destination: string;
}


const BackButton: React.FC<ComponentProps> = ({ destination }) => {
  return (
    <BackButtonContainer to={destination}>
      <FaChevronLeft />
      <span>Back</span>
    </BackButtonContainer>
  );
};

export default BackButton;