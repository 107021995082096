import React from "react";
import styled from "styled-components";
import SectionHeader from "../../common/text/SectionHeader";
import TextLine from "../../common/text/TextLine";
import TextContentContainer from "../shared/TextContentContainer";
import Image from "../shared/Image";

const TextContent = () => {
  return (
    <TextContentContainer>
      <SectionHeader>Manufacturers</SectionHeader>
      <TextLine>Grow your brand recognition by partnering with local and global retailers</TextLine>
      <TextLine>Complete control over who to collaborate with</TextLine>
      <TextLine>Friction-less coordination and collaboration with buyers</TextLine>
    </TextContentContainer>
  );
};

const ImageContent = () => {
  return (
    <Image src="aboutUs/manufacturers.png" alt="description" />
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Manufacturers = () => {
  return (
    <SectionContainer>
      <TextContent />
      <ImageContent />
    </SectionContainer>
  );
};

export default Manufacturers;
