import styled from "styled-components";
import { ComponentStylingProps } from "../../../types/shared";

const TextAreaInput = styled.textarea<ComponentStylingProps>`
  border-radius: 1em;
  height: 10em;
  width: 100%;
  padding: ${({ padding }) => padding || '0.5em'};
  margin: ${({ margin }) => margin || '0'};
`;

export default TextAreaInput;