import React from "react";
import styled from "styled-components";
import BaseProduct from "../../../types/BaseProduct";
import HorizontalTable from "../../common/tables/HorizontalTable";

type ComponentProps = {
  baseProduct: BaseProduct;
}

const PricingContainer = styled.div`
  width: 60%;
  text-align: left;
`;

const Pricing: React.FC<ComponentProps> = ({ baseProduct }) => {
  const headers = [
    "Order Quantity",
    `Price Per ${baseProduct.pricingRate()}`
  ];

  const rows = baseProduct.quantityTierPricings.map((quantityTierPricing) => {
    const pricingRange = quantityTierPricing.maxRangeQuantity ?
      `${quantityTierPricing.minRangeQuantity} - ${quantityTierPricing.maxRangeQuantity}` :
      `${quantityTierPricing.minRangeQuantity}+`;
    const perUnitCost = `$${quantityTierPricing.priceAmount}`;
    return [pricingRange, perUnitCost];
  });

  return (
    <PricingContainer>
      <h3>Pricing</h3>
      <HorizontalTable headers={headers} rows={rows} />
    </PricingContainer>
  );
};

export default Pricing;