import React, { KeyboardEvent, useState } from "react";
import TextInput from "../common/inputs/TextInput";
import { FaSearch as Icon } from "react-icons/fa";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchBarTextInput = styled(TextInput)`
  width: 100%;
`;

const SearchBarIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const SearchBar = () => {
  const [searchText, updateSearchText] = useState<string>("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => updateSearchText(e.target.value);

  const navigate = useNavigate();
  const navigateToProductPage = () => {
    const queryParams: string[] = [];

    if (searchText) queryParams.push(`s=${searchText}`);

    navigate(`/base_products?${queryParams.join("&")}`);
  };

  const onClick = () => navigateToProductPage();
  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') navigateToProductPage();
  };


  return (
    <SearchBarContainer>
      <SearchBarTextInput value={searchText} onChange={onChange} placeholder="Search by product or brand" onKeyUp={onKeyUp} />
      <SearchBarIcon onClick={onClick} />
    </SearchBarContainer>
  );
};

export default SearchBar;