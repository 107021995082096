import React, { useEffect, useState } from "react";
import { getBaseProduct } from "../services/baseProduct";
import { useParams } from "react-router-dom";
import BaseProductDisplay from "../components/baseProduct/BaseProductDisplay";
 import BaseProduct from "../types/BaseProduct";
import { Nullable } from "../types/shared";

const BaseProductPage = () => {
  const { id } = useParams();
  const [baseProduct, setBaseProduct] = useState<Nullable<BaseProduct>>();

  useEffect(() => {
    if (!id) {
      console.error("ID not found");
      return;
    }

    getBaseProduct(id)
      .then(baseProduct => setBaseProduct(baseProduct))
      .catch(err => {
        console.error(err);
        setBaseProduct(null);
        // TODO: some sort of error alerting if base product is null
      });
  }, [id]);

  return (
    <>
      {
        baseProduct ? <BaseProductDisplay baseProduct={baseProduct} />  : null
      }
    </>
  );
};

export default BaseProductPage;