import React from "react";
import BaseProduct from "../../../types/BaseProduct";
import styled from "styled-components";


type ComponentProps = {
  baseProduct: BaseProduct;
}

const SummaryHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Image = styled.img`
  max-width: 10%;
  max-height: 10%;
  object-fit: contain;
`;

const ProductNameContainer = styled.div`
  margin: 2em;
`;

const SummaryHeader: React.FC<ComponentProps> = ({ baseProduct } ) => {
  return (
    <SummaryHeaderContainer>
      <Image src={baseProduct.image} alt="Description" />
      <ProductNameContainer>
        <h3>{baseProduct.productName}</h3>
        <span>Minimum Order Quantity: {baseProduct.minimumOrderQuantity}</span>
      </ProductNameContainer>
    </SummaryHeaderContainer>
  );
};

export default SummaryHeader;