import React from "react";
import styled from "styled-components";

const DetailDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

type ComponentProps = {
  name: string;
  content: string;
}

const Name = styled.span`
  background: #EEF0F4;
  padding: 0.5em;
`;

const Content = styled.span`
  background: #F6F6F6;
  padding: 0.5em;
`;

const DetailDropdown: React.FC<ComponentProps> = ({ name, content }) => {
  return (
    <DetailDropdownContainer>
      <Name>{name}</Name>
      <Content>{content}</Content>
    </DetailDropdownContainer>
  );
};

export default DetailDropdown;