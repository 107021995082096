import React from "react";
import BaseProduct from "../../types/BaseProduct";
import styled from "styled-components";
import BaseProductCard from "./BaseProductCard";

type ComponentProps = {
  baseProducts: BaseProduct[]
}

const BaseProductsDisplayContainer = styled.div`
  width: 90%;
`;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates three columns of equal width */
  gap: 20px; /* Space between items */
`;

const BaseProductsDisplay: React.FC<ComponentProps> = ({ baseProducts }) => {
  const duplicatedBaseProducts = Array(20).fill(baseProducts).flat();
  return (
    <BaseProductsDisplayContainer>
      <ProductList>
        {
          duplicatedBaseProducts.map((product, index) => <BaseProductCard baseProduct={product} key={index}/>)
        }
      </ProductList>
    </BaseProductsDisplayContainer>
  );
};

export default BaseProductsDisplay;