import React from "react";

const HomePage = () => {
  return (
    <>
      <div>Home Page</div>
    </>
  );
};

export default HomePage;
