import { BaseProductData, CustomizationOptions, QuantityTierPricing } from "./shared";

class BaseProduct {
  id: string;
  companyName: string;
  productName: string;
  minimumOrderQuantity: string;
  description: string;
  image: string;
  sample: string;
  shipping: string;
  leadTime: string;
  pricingRateUnit: string;
  pricingRateQuantity: number;
  customizations: CustomizationOptions;
  quantityTierPricings: QuantityTierPricing[];

  constructor(baseProductData: BaseProductData) {
    this.id = baseProductData['id'];
    this.companyName = baseProductData['companyName'];
    this.productName = baseProductData['productName'];
    this.minimumOrderQuantity = baseProductData['minimumOrderQuantity'];
    this.description = baseProductData['description'];
    this.image = baseProductData['image'];
    this.sample = baseProductData['sample'];
    this.shipping = baseProductData['shipping'];
    this.leadTime = baseProductData['leadTime'];
    this.customizations = baseProductData['customizations'];
    this.quantityTierPricings = baseProductData['quantityTierPricings'];
    this.pricingRateUnit = baseProductData['pricingRateUnit'];
    this.pricingRateQuantity = baseProductData['pricingRateQuantity'];
  }

  pricings() {
    return this.quantityTierPricings.map(({ priceAmount }) => priceAmount).sort().reverse();
  }

  minPrice() {
    return this.pricings()[0];
  }

  maxPrice() {
    const pricings = this.pricings();
    return pricings[pricings.length - 1];
  }

  pricingRate() {
    return `${this.pricingRateQuantity} ${this.pricingRateUnit}`;
  }
}

export default BaseProduct;