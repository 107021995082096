import styled from "styled-components";

const CTAInput = styled.input`
  background: #B0DDFF;
  width: 100%;
  height: 3.5em;
  font-weight: bold;
  border: none;
  border-radius: 1em;
  cursor: pointer;
`;

export default CTAInput;