import React from "react";
import styled from "styled-components";

type ComponentProps = {
  name: string;
  content: string;
}

const CustomizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Name = styled.span`
  width: 25%;
`;

const Value = styled.span`

`;

function camelCaseToTitle(camelCase: string): string {
  const words = camelCase.split(/(?=[A-Z])/);

  const capitalizedWords = words.map(word =>
     word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(' ');
}

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Customization: React.FC<ComponentProps> = ({ name, content }) => {
  const processedName = camelCaseToTitle(name);
  const values = content.split("\\n");

  return (
    <CustomizationContainer>
      <Name>{processedName}</Name>
      <ValuesContainer>
        {
          values.map((value: string) => {
            return <Value>{value}</Value>;
          })
        }
      </ValuesContainer>
    </CustomizationContainer>
  );
};

export default Customization;