export function camelCaseToCapitalizedWordsReadableDisplay(camelCaseStr: string): string {
  // Insert a space before all uppercase letters
  const spacedStr = camelCaseStr.replace(/([A-Z])/g, ' $1');
  
  // Capitalize the first letter of each word and lowercase the rest
  const titleCaseStr = spacedStr
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  
  return titleCaseStr;
}