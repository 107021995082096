import React from "react";
import styled from "styled-components";

type ComponentProps = {
  headers: string[];
  rows: string[][]
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: column;
`;

const TableHeading = styled.div`
  padding: 5px;
  border: 1px solid black;
`;

const TableData = styled.div`
  padding: 5px;
  border: 1px solid black;
`;

const HorizontalTable: React.FC<ComponentProps> = ({ headers, rows }) => {
  return (
    <TableContainer>
      <TableRow>
        {
          headers.map(header => <TableHeading>{header}</TableHeading>)
        }
      </TableRow>
      {
        rows.map(row => (
          <TableRow>
            {
              row.map(row => <TableData>{row}</TableData>)
            }
          </TableRow>
        ))
      }
    </TableContainer>
  );
};

export default HorizontalTable;