import React from "react";
import styled from "styled-components";

const Image = styled.img`
  max-height: 12em;
  object-fit: contain;
  margin: 0 3em;
`;

const Logo: React.FC<React.ComponentProps<"img">> = ({ onClick }) => {
  return (
    <>
      <Image src={`${process.env.PUBLIC_URL}/Logo.png`} alt="Logo" onClick={onClick} />
    </>
  );
};

export default Logo;