import React from "react";
import BaseProduct from "../../types/BaseProduct";
import styled from "styled-components";

type ComponentProps = {
  baseProduct: BaseProduct
}

const BaseProductCardContainer = styled.div`
  height: 17em;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled.img`
  max-height: 12em;
  object-fit: contain;
  margin: 0 3em;
`;
// TODO: reusable image

const ProductName = styled.div`
  margin: 0.5em 3em;
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
`;

const CompanyName = styled.div`
  margin: 0.125em 3em;
  text-align: left;
  width: 100%;
`;

const ProductPriceRange = styled.div`
  margin: 0.125em 3em;
  text-align: left;
  width: 100%;
`;

const BaseProductCard: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <BaseProductCardContainer>
      <Image src={baseProduct.image} alt="Description" />
      <ProductName>{baseProduct.productName}</ProductName>
      <CompanyName>{baseProduct.companyName}</CompanyName>
      <ProductPriceRange>{`$${baseProduct.minPrice()} - $${baseProduct.maxPrice()}`}</ProductPriceRange>
    </BaseProductCardContainer>
  );
};

export default BaseProductCard;