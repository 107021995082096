import React, { useState, useEffect } from "react";
import BaseProduct from "../types/BaseProduct";
import { getBaseProducts } from "../services/baseProducts";
import BaseProductsDisplay from "../components/baseProducts/BaseProductsDisplay";
import { Nullable } from "../types/shared";
import { useSearchParams } from "react-router-dom";

const BaseProductsPage = () => {
  const [baseProducts, setBaseProducts] = useState<Nullable<BaseProduct[]>>();

  const [queryParams] = useSearchParams();
  const searchText = queryParams.get('s');


  useEffect(() => {
    getBaseProducts(searchText)
      .then(baseProducts => setBaseProducts(baseProducts))
      .catch(err => {
        console.error(err);
        setBaseProducts(null);
        // TODO: some sort of error alerting if base product is null
      });
  }, [searchText]);

  return (
    <>
      {
        baseProducts ? <BaseProductsDisplay baseProducts={baseProducts} /> : null
      }
    </>
  );
};

export default BaseProductsPage;