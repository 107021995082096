import React from "react";
import Intro from "../components/about/intro";
import Sellers from "../components/about/sellers";
import Manufacturers from "../components/about/manufacturers";
import Features from "../components/about/features";
import MainArea from "../components/common/layout/MainArea";

const AboutUsPage = () => {
  return (
    <MainArea>
      <Intro />
      <h2>Hi, we're OurVersion. Who are you?</h2>
      <Sellers />
      <Manufacturers />
      <h3>Features</h3>
      <h2>Marketplace for product collaboration</h2>
      <Features />
    </MainArea>
  );
};

export default AboutUsPage;