import React from "react";
import styled from "styled-components";
import SectionHeader from "../../common/text/SectionHeader";
import Paragraph from "../../common/text/Paragraph";
import TextContentContainer from "../shared/TextContentContainer";
import Image from "../shared/Image";

const TextContent = () => {
  return (
    <TextContentContainer>
      <SectionHeader>Find your next product creation partner.</SectionHeader>
      <Paragraph>OurVersion is the easiest way to discover and work with brands on your next white label, private label, or branded collaboration products.</Paragraph>
    </TextContentContainer>
  );
};

const ImageContent = () => {
  return (
    <Image src="aboutUs/description.png" alt="description" />
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Intro = () => {
  return (
    <SectionContainer>
      <TextContent />
      <ImageContent />
    </SectionContainer>
  );
};

export default Intro;
