import React from "react";
import styled from "styled-components";
import BaseProduct from "../../../types/BaseProduct";

type ComponentProps = {
  baseProduct: BaseProduct;
}

const MainContentPhotoContainer = styled.div`
  width: 45%;
  padding: 0 2em 0 2em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const MainContentPhoto: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <MainContentPhotoContainer>
      <Image src={baseProduct.image} alt="Description" />
    </MainContentPhotoContainer>
  );
};

export default MainContentPhoto;