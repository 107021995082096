import React from "react";
import BaseProduct from "../../types/BaseProduct";
import styled from "styled-components";
import BaseProductOrderForm from "./form";
import BaseProductOrderSummary from "./summary";
import VerticalDivider from "../common/dividers/VerticalDivider";

type ComponentProps = {
  baseProduct: BaseProduct;
}

const BaseProductOrderDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BaseProductOrderDisplay: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <BaseProductOrderDisplayContainer>
      <BaseProductOrderForm baseProduct={baseProduct} />
      <VerticalDivider />
      <BaseProductOrderSummary baseProduct={baseProduct} />
    </BaseProductOrderDisplayContainer>
  );
};

export default BaseProductOrderDisplay;