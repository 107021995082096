import React from "react";
import styled from "styled-components";
import BaseProduct from "../../../types/BaseProduct";
import SummaryHeader from "./SummaryHeader";
import SummaryQuantityTierPricings from "./SummaryQuantityTierPricings";

type ComponentProps = {
  baseProduct: BaseProduct;
}

const BaseProductOrderSummaryContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BaseProductOrderSummary: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <BaseProductOrderSummaryContainer>
      <SummaryHeader baseProduct={baseProduct} />
      <SummaryQuantityTierPricings baseProduct={baseProduct} />
    </BaseProductOrderSummaryContainer>
  );
};

export default BaseProductOrderSummary;