import React from "react";
import styled from 'styled-components';

const Divider = styled.div`
  width: 2px;
  background-color: black;
  height: auto;
  margin: 0 4em;
`;

const VerticalDivider = () => {
  return <Divider />;
};

export default VerticalDivider;