import React from "react";
import styled from "styled-components";
import BaseProduct from "../../../types/BaseProduct";
import Customization from "./Customization";

type ComponentProps = {
  baseProduct: BaseProduct;
}

const CustomizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
`;

const Customizations: React.FC<ComponentProps> = ({ baseProduct }) => {
  const { customizations } = baseProduct;

  return (
    <CustomizationsContainer>
      <h3>Customizations</h3>
      {
        Object.keys(customizations).map((key) => {
          return <Customization
            key={key}
            name={key}
            content={customizations[key]}
          />;
        })
      }
    </CustomizationsContainer>
  );
};

export default Customizations;